import React from 'react';
import PropTypes from 'prop-types';
import RoleCellSkeleton from 'components/SkeletonScreen/components/RoleCellSkeleton';
import ChangeRoleInProject from 'containers/OneProjectPage/MembersPage/components/ChangeRoleInProject';
import isEmpty from 'lodash/isEmpty';

export const RoleCell = props => {
  const {
    member,
    projectRoles,
    listRequestOption,
    loading,
    onChange,
    disabled,
  } = props;

  if (loading && isEmpty(member) && isEmpty(projectRoles)) {
    return <RoleCellSkeleton />;
  }

  if (!loading && isEmpty(member) && isEmpty(projectRoles)) {
    return <div>-</div>;
  }

  return (
    <ChangeRoleInProject
      disabled={disabled}
      member={member}
      projectRoles={projectRoles}
      listRequestOption={listRequestOption}
      onChange={onChange}
    />
  );
};

RoleCell.propTypes = {
  member: PropTypes.object,
  projectRoles: PropTypes.array,
  listRequestOption: PropTypes.object,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

export default RoleCell;
