const changeRoleTheme = theme => ({
  roleMenuItem: {
    minWidth: 270,
    minHeight: 40,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: theme.palette.background.primary,
    },
    '&:focus': {
      backgroundColor: theme.palette.background.primary,
    },
  },
  selectedMenu: {
    backgroundColor: `${theme.palette.background.primary} !important`,
  },
  contentItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },
  learnMore: {
    display: 'flex',
    alignItems: 'center',
    padding: ' 8px 16px',
  },
  learnMoreText: {
    paddingLeft: '5px',
  },
  checkIcon: {
    color: theme.palette.secondary.main,
    marginInlineStart: '8px',
  },
  formControl: {
    maxWidth: '100%',
  },
});

export default changeRoleTheme;
