import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { locationType } from 'utils/constants';
import Plans from 'components/SvgComponents/icons/Plans';
import Layers from 'components/SvgComponents/icons/Layers';
import ChevronRight from 'components/SvgComponents/icons/ChevronRight';

export const styles = theme => ({
  cellContainer: {
    display: 'flex',
    fontSize: '0.75rem',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cellNameContainer: {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    width: '100%',
  },
  cellTypo: {
    color: theme.palette.text.dark,
    fontFamily: 'Effra medium, Roboto',
  },
});

export const PlanItemCell = props => {
  const { classes, type, name } = props;

  return (
    <div className={classes.cellContainer}>
      <div className={classes.cellNameContainer}>
        {type === locationType.plan || type === locationType.bim ? (
          <Plans width={16} height={16} />
        ) : (
          <Layers width={16} height={16} />
        )}
        <Typography className={classes.cellTypo} noWrap component="div">
          {name}
        </Typography>
      </div>
      {type === locationType.folder ? (
        <ChevronRight width={16} height={16} />
      ) : null}
    </div>
  );
};
PlanItemCell.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.oneOf([...Object.values(locationType)]).isRequired,
  name: PropTypes.string.isRequired,
};

export default withStyles(styles)(PlanItemCell);
