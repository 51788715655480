import { withStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import ChevronDown from 'components/SvgComponents/icons/ChevronDown';
import Placeholder from './Placeholder';

const styles = () => ({
  wrapper: {
    position: 'relative',
    width: '60%',
  },
  chevronDown: {
    top: 'calc(50% - 12px)',
    right: '0',
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'absolute',
    pointerEvents: 'none',
    width: '10px',
    height: '10px',
    margin: '8px',
  },
});

const RoleCellSkeleton = props => {
  const { classes } = props;
  return (
    <div className={classes.wrapper}>
      <Placeholder height="27px" width="100%" />
      <ChevronDown className={classes.chevronDown} />
    </div>
  );
};

RoleCellSkeleton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RoleCellSkeleton);
