import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import globalMessages from 'translations/messages/global-messages';

const Trash = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    width={props.size}
    height={props.size}
    {...props}
  >
    <title>{props.title || props.intl.formatMessage(globalMessages.delete)}</title>
    <path
      fill={props.color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33301 4.00033C1.33301 3.63214 1.63148 3.33366 1.99967 3.33366H13.9997C14.3679 3.33366 14.6663 3.63214 14.6663 4.00033C14.6663 4.36852 14.3679 4.66699 13.9997 4.66699H1.99967C1.63148 4.66699 1.33301 4.36852 1.33301 4.00033Z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66634 2.00033C6.48953 2.00033 6.31996 2.07056 6.19494 2.19559C6.06991 2.32061 5.99967 2.49018 5.99967 2.66699V3.33366H9.99967V2.66699C9.99967 2.49018 9.92944 2.32061 9.80441 2.19559C9.67939 2.07056 9.50982 2.00033 9.33301 2.00033H6.66634ZM11.333 3.33366V2.66699C11.333 2.13656 11.1223 1.62785 10.7472 1.25278C10.3721 0.877706 9.86344 0.666992 9.33301 0.666992H6.66634C6.13591 0.666992 5.6272 0.877706 5.25213 1.25278C4.87706 1.62785 4.66634 2.13656 4.66634 2.66699V3.33366H3.33301C2.96482 3.33366 2.66634 3.63214 2.66634 4.00033V13.3337C2.66634 13.8641 2.87705 14.3728 3.25213 14.7479C3.6272 15.1229 4.13591 15.3337 4.66634 15.3337H11.333C11.8634 15.3337 12.3721 15.1229 12.7472 14.7479C13.1223 14.3728 13.333 13.8641 13.333 13.3337V4.00033C13.333 3.63214 13.0345 3.33366 12.6663 3.33366H11.333ZM3.99967 4.66699V13.3337C3.99967 13.5105 4.06991 13.68 4.19494 13.8051C4.31996 13.9301 4.48953 14.0003 4.66634 14.0003H11.333C11.5098 14.0003 11.6794 13.9301 11.8044 13.8051C11.9294 13.68 11.9997 13.5105 11.9997 13.3337V4.66699H3.99967Z"
    />
  </svg>
);

Trash.propTypes = {
  title: PropTypes.string,
  intl: intlShape.isRequired,
  color: PropTypes.string,
  size: PropTypes.number,
};

Trash.defaultProps = {
  title: '',
  color: '#848F9D',
  size: 16,
};

export default compose(injectIntl)(Trash);
