import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectMembersPage = state => state.membersPage || initialState;

const selectProjectRoles = () =>
  createSelector(selectMembersPage, dataState =>
    dataState.get('projectRoles').toJS(),
  );

const selectSameAndBelowProjectRoles = () =>
  createSelector(selectMembersPage, dataState =>
    dataState.get('sameAndBelowProjectRoles').toJS(),
  );

const selectProjectNonMembers = () =>
  createSelector(selectMembersPage, dataState =>
    dataState.get('nonMembers').toJS(),
  );

export {
  selectProjectRoles,
  selectProjectNonMembers,
  selectSameAndBelowProjectRoles,
};
