import React, { useState } from 'react';

import { Divider, MenuItem, Typography } from '@mui/material';
import { withStyles, useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { useSelector } from 'react-redux';

import Select from 'components/reduxFormComponentsV3/Select';
import Control from 'components/SvgComponents/icons/Control';
import HelpCircle from 'components/SvgComponents/icons/HelpCircle';
import { useFeatureFlags } from 'contexts/FeatureFlagsProvider';
import changeRoleStyle from 'theme/change-role-theme';
import globalMessages from 'translations/messages/global-messages';
import Analytics from 'utils/Analytics';
import { getTranslation } from 'utils/localization';

import { selectSameAndBelowProjectRoles } from '../selectors';

const learnMore = 'learnMore';
function ChangeRoleInProject(props) {
  const { member, projectRoles, classes, listRequestOption, disabled, intl } = props;
  const theme = useTheme();
  const [userRole, setUserRole] = useState(member?.project_role || '');
  const featureFlags = useFeatureFlags();
  const currentRoleAndBellow = useSelector(selectSameAndBelowProjectRoles());

  const handleRedirect = () => {
    Analytics.track('onboarding_project_role_help_opened');
    window.open(featureFlags?.web_help_project_role, '_blank');
  };

  const handleChange = ({ target }) => {
    const { value } = target;
    if (value === learnMore) {
      return handleRedirect();
    }
    setUserRole(projectRoles.find(role => role.id === value));
    return props.onChange({
      listRequestOption,
      userId: member.user_id,
      roleId: value,
    });
  };

  return (
    <Select
      value={userRole?.id}
      disabled={disabled}
      size="small"
      fullWidth={false}
      onChange={handleChange}
      renderValue={() => (
        <Typography variant="subtitleLight">
          {getTranslation(userRole?.names?.translations, userRole?.name)}
        </Typography>
      )}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
    >
      {currentRoleAndBellow.map(option => (
        <MenuItem
          key={option.id || option.guid}
          value={option.id}
          data-guid={option.id}
          classes={
            {
              // root: classes.roleMenuItem,
              // selected: classes.selectedMenu,
            }
          }
        >
          <div className={classes.contentItem}>
            <div>
              <Typography noWrap component="p" variant="body">
                {getTranslation(option?.names?.translations, option?.name)}
              </Typography>
              <Typography noWrap component="p" variant="subtitleLight">
                {getTranslation(option?.descriptions?.translations, option?.description)}
              </Typography>
            </div>
            {userRole?.id === option.id && (
              <Control className={classes.checkIcon} width={22} height={22} />
            )}
          </div>
        </MenuItem>
      ))}
      <Divider />
      <MenuItem className={classes.learnMore} value={learnMore}>
        <HelpCircle width={11} height={11} fill={theme.palette.text.primary} />
        <Typography className={classes.learnMoreText} component="p" variant="subtitle">
          {intl.formatMessage(globalMessages.learn_more_about_roles)}
        </Typography>
      </MenuItem>
    </Select>
  );
}

ChangeRoleInProject.propTypes = {
  member: PropTypes.object,
  intl: intlShape.isRequired,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  projectRoles: PropTypes.arrayOf(PropTypes.object),
  listRequestOption: PropTypes.shape({
    method: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    urlParams: PropTypes.object,
    callBack: PropTypes.func,
  }),
  onChange: PropTypes.func,
};

ChangeRoleInProject.defaultProps = {
  projectRoles: [],
};

export default injectIntl(withStyles(changeRoleStyle)(ChangeRoleInProject));
