export const scope = 'app/containers/GlobalWrapper/';
export const SET_ROUTE_PARAMS = `${scope}SET_ROUTE_PARAMS`;
export const FETCH_SPECIAL_RIGHTS = `${scope}FETCH_SPECIAL_RIGHTS`;
export const SET_SPECIAL_RIGHTS = `${scope}SET_SPECIAL_RIGHTS`;
export const SET_LOADING_FLAG = `${scope}SET_LOADING_FLAG`;
export const SET_ALL_ORGANIZATIONS_LOADING_FLAG = `${scope}SET_ALL_ORGANIZATIONS_LOADING_FLAG`;
export const SAVE_BUSINESS_ORGANISATION = `${scope}SAVE_BUSINESS_ORGANISATION`;
export const DOWNLOAD_ITEM = `${scope}DOWNLOAD_ITEM`;
export const GET_TIMEZONES = `${scope}GET_TIMEZONES`;
export const SET_TIMEZONES = `${scope}SET_TIMEZONES`;
export const GET_MEMBERS = `${scope}GET_MEMBERS`;
export const SET_MEMBERS = `${scope}SET_MEMBERS`;
export const GET_USER_DATA = `${scope}GET_USER_DATA`;
export const INITIALIZE_USER_STORE = `${scope}INITIALIZE_USER_STORE`;
export const ADD_USER_TO_STORE = `${scope}ADD_USER_TO_STORE`;
export const SET_LOGGED_USER_DATA = `${scope}SET_LOGGED_USER_DATA`;
export const GET_COMPANY_INFO = `${scope}GET_COMPANY_INFO`;
export const CREATE_AND_ATTACH_MEDIA = `${scope}CREATE_AND_ATTACH_MEDIA`;
export const FETCH_ALL_ORGANIZATIONS = `${scope}FETCH_ALL_ORGANIZATIONS`;
export const SET_ALL_ORGANIZATIONS = `${scope}SET_ALL_ORGANIZATIONS`;
export const REMOVE_ORGANIZATION = `${scope}REMOVE_ORGANIZATION`;
export const GET_BUSINESS_ORGANIZATION = `${scope}GET_BUSINESS_ORGANIZATION`;
export const SET_BUSINESS_ORGANIZATION = `${scope}SET_BUSINESS_ORGANIZATION`;
export const GET_PROJECT = `${scope}GET_PROJECT`;
export const SET_PROJECT = `${scope}SET_PROJECT`;
export const ADD_TO_PROJECT_BY_INVITATION = `${scope}ADD_TO_PROJECT_BY_INVITATION`;
export const CHECK_EMAIL_REGISTRATION = `${scope}CHECK_EMAIL_REGISTRATION`;
export const SET_EMAIL_REGISTRATION = `${scope}SET_EMAIL_REGISTRATION`;
export const GET_CURRENT_OFFER = `${scope}GET_CURRENT_OFFER`;
export const VERIFY_EMAIL = `${scope}VERIFY_EMAIL`;
export const SET_POPUP_BLOCKER_ACTIVE = `${scope}SET_POPUP_BLOCKER_ACTIVE`;
export const SET_POPUP_BLOCKER_BLOB = `${scope}SET_POPUP_BLOCKER_BLOB`;
export const CLEAR_POPUP_BLOCKER_BLOB = `${scope}CLEAR_POPUP_BLOCKER_BLOB`;
export const RESET_POPUP_BLOCKER_BLOB = `${scope}RESET_POPUP_BLOCKER_BLOB`;
export const SET_WORKSPACE = `${scope}SET_WORKSPACE`;
export const GET_WORKSPACE = `${scope}GET_WORKSPACE`;
export const GET_WORKSPACE_BY_ID = `${scope}GET_WORKSPACE_BY_ID`;
export const SAVE_WORKSPACE = `${scope}SAVE_WORKSPACE`;
export const LEAVE_PROJECT = `${scope}LEAVE_PROJECT`;
export const DELETE_PROJECT = `${scope}DELETE_PROJECT`;
export const SET_PROJECT_DELETED = `${scope}SET_PROJECT_DELETED`;
export const GET_USER_LINKED_COMPANIES = `${scope}GET_USER_LINKED_COMPANIES`;
export const SET_USER_LINKED_COMPANIES = `${scope}SET_USER_LINKED_COMPANIES`;
export const GET_PLAN = `${scope}GET_PLAN`;
export const SET_PLAN = `${scope}SET_PLAN`;
export const SET_PLAN_FOLDER = `${scope}SET_PLAN_FOLDER`;
export const FETCH_LOGOS = `${scope}FETCH_LOGOS`;
export const ATTACH_LOGOS = `${scope}ATTACH_LOGOS`;
export const DETACH_LOGO = `${scope}DETACH_LOG`;
export const SET_LOGOS = `${scope}SET_LOGOS`;
