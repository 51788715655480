import React, { useEffect, useState } from 'react';

import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';
import Tree, { TreeNode } from 'rc-tree';
import { injectIntl, intlShape } from 'react-intl';
import { useDispatch } from 'react-redux';

import Clipboard from 'components/SvgComponents/icons/Clipboard';
import PartWhite from 'components/SvgComponents/icons/PartBlack';
import SectionWhite from 'components/SvgComponents/icons/SectionBlack';
import { setGlobalError } from 'containers/ErrorHandler/actions';
import TypeFieldIcon from 'containers/FormPage/FormStructure/components/TypeFieldIcon';
import globalMessages from 'translations/messages/global-messages';
import { typeConst, typeFieldDataConst } from 'utils/constants';

import { Div_TreeWrapper } from './styles';

const SectionTree = ({
  className,
  data,
  disabled,
  isDraggable,
  intl,
  searchTreeText,
  selectedKeys,
  selectedTreeNode,
  visibleFieldTypes,
  onSelectItem,
  setNewTreeData,
}) => {
  const [hasError, setHasError] = useState(false);
  const [shouldExpand] = useState(true);
  const [errorMessage, setErrorMessage] = useState(0);
  const theme = useTheme();
  const dispatch = useDispatch();

  const processLoopData = (item, key, callback, index, arr, loop) => {
    if (item.key === key) {
      callback(item, index, arr);
      return;
    }
    if (item.children) {
      loop(item.children, key, callback);
    }
  };
  // is on level X - X and dropped element is not dropped inside target element
  const canDropPart = node => node.props.pos.split('-').length === 2 && !node.props.dragOver;

  // is on level X - X - X and dropped element is not dropped inside target element
  // OR
  // is on level X - X and dropped element is dropped inside target element
  const canDropSection = node =>
    (node.props.pos.split('-').length === 3 && !node.props.dragOver) ||
    (node.props.pos.split('-').length === 2 && node.props.dragOver);

  const validateDropElement = (node, dragNode) => {
    const { dragOverGapTop, dragOverGapBottom } = node.props;
    const { item } = dragNode.props;

    const droppablePosArray = node.props.pos.split('-');
    const droppablePos = Number(droppablePosArray[droppablePosArray.length - 1]);

    const draggablePosArray = dragNode.props.pos.split('-');
    const draggablePos = Number(draggablePosArray[draggablePosArray.length - 1]);

    if (
      node.props.item.sectionId === dragNode.props.item.sectionId &&
      ((dragOverGapTop && draggablePos + 1 === droppablePos) ||
        (dragOverGapBottom && draggablePos - 1 === droppablePos))
    ) {
      return {
        success: false,
      };
    }

    if (item.type === typeConst.part && !canDropPart(node)) {
      setHasError(true);
      setErrorMessage(1);
      return {
        success: false,
      };
    }

    if (item.type === typeConst.section && !canDropSection(node)) {
      setHasError(true);
      setErrorMessage(2);
      return {
        success: false,
      };
    }
    if (item.type === typeConst.section && dragNode.props.numberOfChildrenOfParent === 1) {
      setHasError(true);
      setErrorMessage(4);
      return {
        success: false,
      };
    }
    return {
      success: true,
      error: null,
    };
  };

  const addParentPartOrSectionIdToNode = (node, parent) => {
    const newNode = node;
    if (parent && node.partId) {
      newNode.partId = parent.partId;
    }
    if (parent && node.type === typeConst.field) {
      newNode.sectionId = parent.id;
    }
  };

  const processDragToGap = (loop, copyData, dropKey, dropPosition, dragObj, info) => {
    const dragObjCopy = { ...dragObj };
    let ar;
    loop(copyData, dropKey, (item, index, arr) => {
      ar = arr;
    });
    const itemToDrop = info.node.props.item;
    addParentPartOrSectionIdToNode(dragObj, itemToDrop);
    // alter order property of the draged item
    ar.splice(dropPosition, 0, dragObjCopy);
    // alter order property of the all items after the inserted one
    for (let indexLoop = 0; indexLoop < ar.length; indexLoop += 1) {
      ar[indexLoop].order = indexLoop;
    }
    return dragObjCopy;
  };

  const onDrop = info => {
    const dropValidation = validateDropElement(info.node, info.dragNode);
    if (dropValidation.success) {
      const dropKey = info.node.props.eventKey;
      const dragKey = info.dragNode.props.eventKey;

      const destinationArr = info.node.props.pos.split('-');
      const positionDestination = Number(destinationArr[destinationArr.length - 1]);
      let dropPosition = positionDestination;
      if (info.node.props.dragOverGapBottom) {
        // if the element is dropped on the bottom this means it's after the position ( +1)
        dropPosition += 1;
      }
      const loop = (loopData, key, callback) => {
        loopData.forEach((item, index, arr) => {
          processLoopData(item, key, callback, index, arr, loop);
        });
      };
      const copyData = [...data];
      let dragObj;
      loop(copyData, dragKey, (item, index, arr) => {
        const referenceArr = arr;
        referenceArr.splice(index, 1);
        dragObj = item;
        // decrease order property by -1 to all elements after the moved object
        for (let indexLoop = index; indexLoop < arr.length; indexLoop += 1) {
          referenceArr[indexLoop].order = indexLoop + 1;
        }
      });
      if (info.dropToGap) {
        dragObj = processDragToGap(loop, copyData, dropKey, dropPosition, dragObj, info);
      } else {
        loop(copyData, dropKey, item => {
          const it = item;
          it.children = it.children || [];
          addParentPartOrSectionIdToNode(dragObj, it);
          it.children.push(dragObj);
        });
      }
      setHasError(false);
      setNewTreeData(copyData);
    }
    info.dragNode.onExpand(info.event, info.node);
  };

  const doesSearchMatchTitle = (name, searchText) => {
    const lowerCasedTitle = name.toLowerCase();
    const lowerCasedSearchText = searchText ? searchText.toLowerCase() : '';
    return lowerCasedSearchText && lowerCasedTitle.indexOf(lowerCasedSearchText) > -1;
  };

  const getNodeClassname = (name, type, searchText) => {
    if (doesSearchMatchTitle(name, searchText)) {
      return 'tree-node-search-highighted';
    }
    return `tree-node-standard ${type}`;
  };

  const getNodeIcon = item => {
    if (item.type === typeConst.part) {
      return <PartWhite width="16" height="16" className="tree-icon" />;
    }
    if (item.type === typeConst.section) {
      return <SectionWhite width="16" height="16" className="tree-icon" />;
    }
    if (item.type === typeConst.field) {
      return (
        <span className="tree-icon">
          <TypeFieldIcon type={item.type_field_presentation || typeFieldDataConst.shortAnswer} />
        </span>
      );
    }
    return <Clipboard className="tree-icon" />;
  };

  const loopOverTreeStructure = (loopData, selectedKeysLoop, searchText) =>
    loopData.map(item => {
      if (
        visibleFieldTypes &&
        visibleFieldTypes.length &&
        item?.form_fields?.length &&
        item?.form_fields[0].type &&
        (visibleFieldTypes.indexOf(item?.form_fields[0].type) === -1 ||
          item?.form_fields[0].type_field_data !== 'Number')
      ) {
        return null;
      }
      if (item.children && item.children.length) {
        return (
          <TreeNode
            icon={getNodeIcon(item)}
            key={item.key}
            expanded
            autoExpandParent={doesSearchMatchTitle(item.name, searchText) || shouldExpand}
            defaultExpandAll={doesSearchMatchTitle(item.name, searchText) || shouldExpand}
            item={item}
            numberOfChildrenOfParent={loopData.length}
            className={getNodeClassname(item.name, item.type, searchText)}
            title={item.name}
          >
            {loopOverTreeStructure(item.children, selectedKeysLoop, searchText)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.key}
          icon={getNodeIcon(item)}
          expanded
          defaultExpandAll={doesSearchMatchTitle(item.name, searchText) || shouldExpand}
          autoExpandParent={doesSearchMatchTitle(item.name, searchText) || shouldExpand}
          numberOfChildrenOfParent={loopData.length}
          className={getNodeClassname(item.name, item.type, searchText)}
          item={item}
          title={item.name}
        />
      );
    });

  const getTree = () => {
    const { updateTree } = selectedTreeNode;

    return (
      <Tree
        disabled={disabled}
        key={`tree-${searchTreeText}-${updateTree}`}
        draggable={isDraggable}
        onDrop={onDrop}
        onSelect={onSelectItem}
        expanded
        autoExpandParent
        selectedKeys={selectedKeys}
        defaultExpandParent
        defaultExpandAll={shouldExpand}
      >
        {loopOverTreeStructure(data, selectedKeys, searchTreeText)}
      </Tree>
    );
  };

  const getErrorMessage = () => {
    if (errorMessage === 1) {
      return globalMessages.cannot_move_part;
    }
    if (errorMessage === 2) {
      return globalMessages.cannot_move_section;
    }
    if (errorMessage === 4) {
      return globalMessages.cannot_move_single_part_child;
    }
    return globalMessages.status_no_permission;
  };

  useEffect(
    () => {
      if (hasError) {
        dispatch(setGlobalError({ customErrorMessageIntl: getErrorMessage() }));
        setHasError(false);
      }
    },
    [hasError],
  );

  return (
    <div className={className}>
      <Div_TreeWrapper theme={theme}>{data.length ? getTree() : null}</Div_TreeWrapper>
    </div>
  );
};

SectionTree.propTypes = {
  isDraggable: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.array,
  selectedKeys: PropTypes.arrayOf(PropTypes.string),
  selectedTreeNode: PropTypes.object,
  setNewTreeData: PropTypes.func,
  intl: intlShape.isRequired,
  onSelectItem: PropTypes.func,
  searchTreeText: PropTypes.string,
  visibleFieldTypes: PropTypes.array,
};

export default injectIntl(SectionTree);
