import { fromJS } from 'immutable';
import {
  SET_PROJECT_ROLES,
  SET_PROJECT_NON_MEMBERS,
  SET_SAME_AND_BELOW_PROJECT_ROLES,
} from './constants';

export const initialState = fromJS({
  projectRoles: [],
  sameAndBelowProjectRoles: [],
  members: [],
  nonMembers: [],
});

function membersPageReducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROJECT_ROLES:
      return state.set('projectRoles', fromJS(action.roles));
    case SET_SAME_AND_BELOW_PROJECT_ROLES:
      return state.set('sameAndBelowProjectRoles', fromJS(action.roles));
    case SET_PROJECT_NON_MEMBERS:
      return state.set('nonMembers', fromJS(action.nonMembers));
    default:
      return state;
  }
}
export default membersPageReducer;
