import React, { Component } from 'react';

import { Typography } from '@mui/material';
import { withStyles } from '@mui/styles';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import { debounce, isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import uuid from 'uuid';

import {
  setCurrentClickPosition,
  setMenuDisplay,
  setMenuReference,
} from 'components/ContextMenu/actions';
import EmptyTable from 'components/EmptyTable';
import FiltersWrap from 'components/Filters/FiltersWrap';
import SearchTextField from 'components/SearchTextFieldV3';
import { selectRouteParams } from 'containers/GlobalWrapper/selectors';
import combineStyles from 'theme/combineStyles';
import { styles as tableStyle } from 'theme/table-style';
import globalMessages from 'translations/messages/global-messages';
import { apiFetchItemsCount, sortDirectionConst } from 'utils/constants';
import localStorageUser from 'utils/localStorageUser';

import CheckBox from '../CheckBox';
import UnCheckAllBox from '../SvgComponents/icons/UnCheckAllBox';
import {
  clearActiveFilters,
  clearCheckedItems,
  clearSearchText,
  clearSortData,
  getItems,
  resetTableState,
  setActiveFilters,
  setCheckedItems,
  setLoadingFlag,
  setSearchText,
  setSortData,
} from './actions';
import ActionsBar from './components/ActionsBar';
import {
  renderMemberCellWithUserId,
  renderAssigneeCell,
  renderCommunicationCell,
  renderCompanyCell,
  renderDateCell,
  renderDetailCell,
  renderDetailedDateCell,
  renderDocumentCell,
  renderForm,
  renderFormTemplate,
  renderLanguage,
  renderLoadedMemberCell,
  renderMediaCell,
  renderMemberCell,
  renderMembersAvatarsCell,
  renderObservationCell,
  renderPlanFolder,
  renderPlanItem,
  renderPriority,
  renderPriorityCell,
  renderRoleCell,
  renderStatus,
  renderStatusCell,
  renderSwitchCell,
  renderTextCell,
} from './components/cellRenderer';
import Column from './components/Column';
import SortDirection from './components/sortDirection';
import TableView from './components/TableView';
import {
  selectActiveFilters,
  selectCheckedItems,
  selectIsEmptyTable,
  selectItems,
  selectItemsSortData,
  selectLoadingFlag,
  selectSearchCount,
  selectSearchText,
  selectUniqueKey,
} from './selectors';

export {
  renderTextCell,
  renderDateCell,
  renderDetailedDateCell,
  renderMediaCell,
  renderMemberCell,
  renderMemberCellWithUserId,
  renderMembersAvatarsCell,
  renderDetailCell,
  renderRoleCell,
  renderStatusCell,
  renderPriorityCell,
  renderObservationCell,
  renderAssigneeCell,
  renderLoadedMemberCell,
  renderCommunicationCell,
  renderCompanyCell,
  renderDocumentCell,
  renderPriority,
  renderForm,
  renderFormTemplate,
  renderStatus,
  renderSwitchCell,
  renderPlanItem,
  Column,
  renderLanguage,
  renderPlanFolder,
};

const STATUSLOADING = 1;
const STATUSLOADED = 2;
const NAMESPACE = 'table';

export class InfiniteTableSelfStanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadedRowCount: 0,
      loadedRowsMap: {},
      loadingRowCount: apiFetchItemsCount,
      sortedList: fromJS([]),
      showError: false,
    };
    const { listRequestOption } = props;
    const { namespace = NAMESPACE } = listRequestOption;
    this.timeoutIdMap = {};
    this.props.dispatchClearSearchText(namespace);
    this.props.dispatchClearActiveFilters(namespace);
    if (this.props.defaultActiveFilters) {
      this.props.dispatchSetActiveFilters(this.props.defaultActiveFilters, namespace);
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { sortBy, sortDirection, sortTransformer } = props;

    const sortList = ({ list }) => {
      let sortedList = list.sortBy(item => sortTransformer(item.get(sortBy)));
      sortedList = sortedList.update(
        li => (sortDirection === SortDirection.DESC ? li.reverse() : li),
      );
      return sortedList;
    };

    if (
      props.list?.size !== undefined &&
      (props.list?.size !== state.sortedList.size || !props.list.equals(state.sortedList))
    ) {
      return {
        sortedList: sortList({ list: props.list }),
      };
    }
    return null;
  }

  componentDidMount() {
    const { sortBy, sortDirection, checkable, listRequestOption } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    const { urlParams } = listRequestOption;
    if (sortBy) {
      const splitSortBy = sortBy.split('.');
      const newSort = {
        propertyName: splitSortBy[0],
      };
      if (splitSortBy.length === 2) {
        [newSort.propertyEntityName, newSort.propertyName] = splitSortBy;
      }
      this.props.dispatchSetSortData(
        {
          sortOrder: sortDirectionConst[sortDirection],
          ...newSort,
        },
        namespace,
      );
    }
    const limit = urlParams?.limit ? urlParams?.limit : apiFetchItemsCount;

    this.fetchData(0, limit);
    if (checkable) {
      this.props.dispatchClearCheckedItems(namespace);
    }
  }

  componentWillUnmount() {
    const { listRequestOption } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    Object.keys(this.timeoutIdMap).forEach(timeoutId => {
      clearTimeout(timeoutId);
    });
    this.props.dispatchResetTableState(namespace);
  }

  shouldComponentUpdate(nextProps) {
    const { checkable, listRequestOption, tableKey } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    const { url, method, urlParams, excludeParamsAtSearch, dataKey } = listRequestOption;
    const nextListRequestOption = nextProps.listRequestOption;

    const tempRequestOption = {
      url,
      method,
      urlParams,
      dataKey,
      excludeParamsAtSearch,
    };
    const nextTempRequestOption = {
      url: nextListRequestOption.url,
      method: nextListRequestOption.method,
      urlParams: nextListRequestOption.urlParams,
      excludeParamsAtSearch: nextListRequestOption.excludeParamsAtSearch,
      dataKey: nextListRequestOption.dataKey,
    };
    // this case is when listRequestOption changed
    if (!isEqual(tempRequestOption, nextTempRequestOption)) {
      this.props.dispatchClearActiveFilters(namespace);
      this.props.dispatchClearSearchText(namespace);
      if (checkable) {
        this.props.dispatchClearCheckedItems(namespace);
      }
      this.props.dispatchFetchMoreItems(
        {
          ...nextTempRequestOption,
          urlParams: {
            ...nextTempRequestOption?.urlParams,
            offset: 0,
            limit: apiFetchItemsCount,
          },
          onError: () => {
            this.clearTableData();
            this.setState({
              showError: true,
            });
          },
        },
        namespace,
      );
      this.clearTableData();
      return false;
    }

    if (tableKey !== nextProps.tableKey) {
      this.loadMoreRowsStartIndex = 0;
      this.loadMoreRowsStopIndex = apiFetchItemsCount;
      this.clearTableData();
      return false;
    }
    return true;
  }

  renderCheckBox = params => {
    const {
      colorCheckBox,
      checkedItems,
      disableItemCheckable,
      classes,
      checkedPropertyNameMatch,
    } = this.props;
    const { rowData } = params;
    const stringRowData = JSON.stringify(rowData, null, 2);
    const parsedRowData = JSON.parse(stringRowData);

    if (!Object.keys(parsedRowData).length || disableItemCheckable(parsedRowData)) {
      return null;
    }

    return (
      <CheckBox
        color={colorCheckBox || 'secondary'}
        checked={
          !!checkedItems.find(
            item => item[checkedPropertyNameMatch] === parsedRowData[checkedPropertyNameMatch],
          )
        }
        className={classes.allCheckBox}
        onClick={e => e.stopPropagation()}
        onChange={this.handleCheckBox(parsedRowData)}
        disableRipple
      />
    );
  };

  renderHeaderCheckbox = () => {
    const { colorCheckBox, checkedItems, searchCount, classes, canCheckAll } = this.props;

    if (!canCheckAll) {
      return null;
    }

    return (
      <CheckBox
        color={colorCheckBox || 'secondary'}
        checked={!!checkedItems.length}
        checkedIcon={
          checkedItems.length < searchCount ? <UnCheckAllBox width="16" height="16" /> : null
        }
        onChange={this.handleAllCheckBox}
        className={classes.allCheckBox}
        disableRipple
      />
    );
  };

  renderTable() {
    const {
      loadingList,
      headerClassName,
      checkedItems,
      headerHeight,
      checkable,
      rowHeight,
      tableClassName,
      getRowClassName,
      checkBoxColumnClassName,
      listRequestOption,
      searchCount,
      searchText,
      button,
      isEmptyTable,
      checkedPropertyNameMatch,
      onRowClick,
      hasHeader,
      onHandleItems,
      deleteDuplicateFormTemplate,
      threshold,
      isFiltering,
      filteringEmptyMessage,
    } = this.props;

    const { namespace = NAMESPACE } = listRequestOption;
    const { sortedList, showError } = this.state;
    const hasNextPage = sortedList.size < searchCount;

    const sortedListToJS = sortedList.toJS();

    const sortedListJS = sortedListToJS
      .filter(c => c?.enabled)
      .concat(sortedListToJS.filter(c => !c?.enabled));

    if (hasHeader && !searchText) {
      sortedListJS.splice(0, 0, { internalTableHeader: true });
    }

    const newSortedListJSWithIds = [];

    if (deleteDuplicateFormTemplate) {
      sortedListJS.forEach(objet => {
        const sortedListJSWithIds = newSortedListJSWithIds.find(o => o.name === objet.name);
        // ids is an array of id of duplicate form template
        if (sortedListJSWithIds) {
          if (!sortedListJSWithIds.ids.includes(objet.id)) {
            sortedListJSWithIds.ids.push(objet.id);
            sortedListJSWithIds.enabledAccordingToId.push(objet.enabled);
            // update enabled if one of the duplicate form template is enabled
            if (sortedListJSWithIds.enabled === false && objet.enabled === true) {
              sortedListJSWithIds.enabled = true;
            }
          }
        } else {
          newSortedListJSWithIds.push({
            ...objet,
            ids: [objet.id],
            enabledAccordingToId: [objet.enabled],
          });
        }
      });
    }

    return (
      <TableView
        filteringNoResultMessage={filteringEmptyMessage}
        onRowRightClick={this.onRowRightClick}
        isNextPageLoading={loadingList}
        hasNextPage={hasNextPage}
        tableClassName={tableClassName}
        getRowClassName={getRowClassName}
        checkedItems={checkedItems}
        list={deleteDuplicateFormTemplate ? fromJS(newSortedListJSWithIds) : fromJS(sortedListJS)}
        rowHeight={rowHeight}
        onRowClick={this.handleRowClick}
        checkable={checkable}
        isEmptyTable={isEmptyTable}
        headerHeight={headerHeight}
        headerClassName={headerClassName}
        onLoadMoreRows={this.loadMoreRows}
        namespace={namespace}
        isFiltering={isFiltering}
        listRequestOption={listRequestOption}
        noDataRenderer={this.noDataRenderer}
        onIsRowLoaded={this.isRowLoaded}
        onSortChange={this.handleSortChange}
        button={!isEmptyTable ? button : null}
        checkedPropertyNameMatch={checkedPropertyNameMatch}
        onHandleItems={onHandleItems}
        showCursor={checkable || !!onRowClick}
        showError={showError}
        threshold={threshold}
      >
        {checkable ? (
          <Column
            width={52}
            minWidth={52}
            flexGrow={0}
            className={checkBoxColumnClassName}
            dataKey="checked"
            cellRenderer={this.renderCheckBox}
            headerRenderer={this.renderHeaderCheckbox}
          />
        ) : (
          <Column cellRenderer={() => null} />
        )}
        {this.props.children}
      </TableView>
    );
  }

  renderFilters() {
    const {
      filterList,
      quickFilterClassName,
      activeFilters,
      isEmptyTable,
      filterActionsRendered,
      checkedItems,
    } = this.props;

    if (isEmptyTable) {
      return null;
    }

    return (
      <FiltersWrap
        filterList={filterList}
        disabled={isEmptyTable}
        quickFilterClassName={quickFilterClassName}
        activeFilters={activeFilters}
        onChangeFilters={this.handleFilterChange}
        onClearFilters={this.handleClearFilters}
        filterActions={filterActionsRendered({ checkedItems })}
      />
    );
  }

  renderSearch = disabled => {
    const {
      autoFocus,
      classes,
      searchRerenderer,
      searchable,
      searchText,
      searchClassName,
      searchBarClassName,
      searchInputProps,
      searchComponent,
      checkable,
      paddingLeftForSearch,
    } = this.props;

    if (!searchable) {
      return null;
    }

    return (
      <>
        {searchComponent || (
          <div
            className={classNames({
              [classes.searchBar]: true,
              [classes.withPaddingLeft]: (paddingLeftForSearch || checkable) && searchRerenderer,
              [searchBarClassName]: !!searchBarClassName,
            })}
          >
            <SearchTextField
              autoFocus={autoFocus}
              searchText={searchText}
              className={classNames({
                [classes.searchText]: true,
                [searchClassName]: !!searchClassName,
              })}
              onChange={this.handleSearchTextChange}
              disabled={disabled}
              searchInputProps={searchInputProps}
            />

            {searchRerenderer && searchRerenderer()}
          </div>
        )}
      </>
    );
  };

  render() {
    const {
      searchable,
      searchText,
      list,
      loadingList,
      activeFilters,
      rendererTableHeader,
      checkedItems,
      searchCount,
      actionsRerenderer,
      isEmptyTable,
      listRequestOption,
      renderAddButton,
      isFiltering,
      actionsClassName,
      disableSearch,
      noDataAction,
      displaySearchBeforeActions,
    } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    if (!list && !isFiltering) {
      return this.noDataRenderer();
    }

    const disabled =
      disableSearch ||
      (!isFiltering
        ? !list?.size && !loadingList && !searchText && !activeFilters?.length
        : false);

    const showSearch = !(searchable && isEmptyTable && !searchText) || isFiltering;
    const showActionRender = actionsRerenderer && (showSearch || noDataAction);
    const showTableHeader = rendererTableHeader && (!isEmptyTable || isFiltering);

    return (
      <>
        {displaySearchBeforeActions && showSearch && this.renderSearch(disabled)}
        {showActionRender && (
          <ActionsBar disabled={disabled} className={actionsClassName}>
            {actionsRerenderer({
              checkedItems,
              searchCount,
              searchText,
              listRequestOption: {
                namespace,
                ...listRequestOption,
              },
            })}
          </ActionsBar>
        )}
        {!displaySearchBeforeActions && showSearch && this.renderSearch(disabled)}
        {this.renderFilters()}
        {showTableHeader &&
          rendererTableHeader({
            isFiltering,
            isEmptyTable,
            checkedItems,
            searchCount,
            searchText,
            listRequestOption: {
              namespace,
              ...listRequestOption,
            },
          })}
        {this.renderTable()}
        {!isEmptyTable && renderAddButton ? renderAddButton() : null}
      </>
    );
  }

  loadMoreRows = ({ startIndex, stopIndex }) => {
    const { loadingList, list } = this.props;
    const { loadedRowsMap, loadingRowCount } = this.state;
    const increment = apiFetchItemsCount;
    const start = startIndex === 0 ? 0 : list.size;

    for (let i = startIndex; i <= stopIndex; i += 1) {
      loadedRowsMap[i] = STATUSLOADING;
    }

    this.setState({
      loadingRowCount: loadingRowCount + increment,
    });

    if (!loadingList) {
      this.fetchData(start, increment);
    }

    const timeoutId = setTimeout(() => {
      const { loadedRowCount } = this.state;

      delete this.timeoutIdMap[timeoutId];

      for (let i = startIndex; i <= stopIndex; i += 1) {
        loadedRowsMap[i] = STATUSLOADED;
      }

      this.setState({
        loadingRowCount: loadingRowCount - increment,
        loadedRowCount: loadedRowCount + increment,
      });

      promiseResolver();
    }, 1000 + Math.round(Math.random() * 2000));

    this.timeoutIdMap[timeoutId] = true;

    let promiseResolver;

    return new Promise(resolve => {
      promiseResolver = resolve;
    });
  };

  fetchData = (startIndex, stopIndex) => {
    const { listRequestOption } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    if (startIndex === 0) {
      this.clearTableData();
    }
    const urlParams = {
      ...listRequestOption.urlParams,
      offset: startIndex,
      limit: stopIndex,
    };
    this.props.dispatchFetchMoreItems(
      {
        ...listRequestOption,
        urlParams,
        onError: () => {
          this.clearTableData();
          this.setState({
            showError: true,
          });
        },
      },
      namespace,
    );
    this.loadMoreRowsStartIndex = startIndex;
    this.loadMoreRowsStopIndex = stopIndex;
  };

  clearTableData = () => {
    this.setState({
      sortedList: fromJS([]),
      loadedRowCount: 0,
      loadedRowsMap: {},
      loadingRowCount: apiFetchItemsCount,
    });
    if (this.props.savedFilterData) {
      const storedFilters = localStorageUser.getFilterByProjectAndModule(
        this.props.savedFilterData,
      );
      if (storedFilters) this.props.dispatchSetActiveFilters(storedFilters);
    }
  };

  isRowLoaded = ({ index }) => {
    const { loadedRowsMap } = this.state;
    return !!loadedRowsMap[index]; // STATUSLOADING or STATUSLOADED
  };

  noDataRenderer = () => {
    const { classes } = this.props;
    const { sortedList } = this.state;
    const { loadingList, list, intl, noDataRenderer, isEmptyTable } = this.props;

    let externalNoData = null;
    if (noDataRenderer && isEmptyTable) {
      externalNoData = <div className={classes.emptyTable}>{noDataRenderer()}</div>;
    }

    if (!loadingList && (!sortedList || sortedList.size === 0) && list?.size === 0) {
      return (
        externalNoData || (
          <EmptyTable
            title={
              <Typography variant="h5" color="textSecondary">
                {intl.formatMessage(globalMessages.no_result_found)}
              </Typography>
            }
          />
        )
      );
    }

    return null;
  };

  callbackFetchData = () => {
    this.debounceFetchData(0, apiFetchItemsCount);
  };

  handleSortChange = ({ sortBy, sortDirection }) => {
    const { listRequestOption } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    const sortedList = this.sortList({ sortBy, sortDirection });
    this.setState({ sortedList /* , sortDirection */ });

    // new sort structure
    const splitSortBy = sortBy.split('.');
    const newSort = {
      propertyName: splitSortBy[0],
    };
    if (splitSortBy.length === 2) {
      [newSort.propertyEntityName, newSort.propertyName] = [
        splitSortBy[0],
        splitSortBy[1].replace('[]', '.'),
      ];
    }

    this.clearTableData();
    this.props.dispatchSetSortData(
      {
        sortOrder: sortDirectionConst[sortDirection],
        ...newSort,
      },
      namespace,
    );
    this.callbackFetchData();
  };

  sortList = ({ sortBy, sortDirection }) => {
    const { list, sortTransformer } = this.props;
    let sortedList = list.sort(item => sortTransformer(item.get(sortBy)));
    sortedList = sortedList.update(
      li => (sortDirection === SortDirection.DESC ? li.reverse() : li),
    );
    return sortedList;
  };

  handleSearchTextChange = value => {
    const { searchText, sortData, listRequestOption } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    const { propertyEntityName, propertyName } = sortData;

    // clear sort filter if search by text
    if (propertyEntityName || propertyName) {
      this.props.dispatchClearSortData(namespace);
    }

    if (value === '') {
      this.props.dispatchClearSearchText(namespace);
    }

    if (searchText !== value) {
      this.props.dispatchSetSearchText(value, namespace);
      this.debounceFetchData(0, apiFetchItemsCount);
    }
  };

  handleClearFilters = () => {
    const { listRequestOption, savedFilterData } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    this.props.dispatchClearActiveFilters(namespace);
    if (savedFilterData) {
      localStorageUser.clearFilterByProjectAndModule({ ...savedFilterData });
    }
    this.debounceFetchData(0, apiFetchItemsCount);
  };

  handleFilterChange = filters => {
    const { listRequestOption, savedFilterData } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    const activeFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== null),
    );
    this.props.dispatchSetActiveFilters(activeFilters, namespace);
    if (savedFilterData) {
      localStorageUser.saveFilterByProjectAndModule({ filters: activeFilters, ...savedFilterData });
    }
    this.debounceFetchData(0, apiFetchItemsCount);
  };

  handleAllCheckBox = (event, value) => {
    const { list, listRequestOption, disableItemCheckable } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    const StringList = JSON.stringify(list, null, 2);
    const items = JSON.parse(StringList);
    const checkableItems = items.filter(item => !disableItemCheckable(item));
    if (value) {
      this.props.dispatchSetCheckedItems(checkableItems, namespace);
    } else {
      this.props.dispatchSetCheckedItems([], namespace);
    }
  };

  handleCheckBox = rowData => () => {
    const {
      checkedItems,
      checkedPropertyNameMatch,
      listRequestOption,
      canCheckOnlyOneItem,
    } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    const stringRowData = JSON.stringify(rowData, null, 2);
    const parsedRowData = JSON.parse(stringRowData);

    const findItem = checkedItems.find(
      item => item[checkedPropertyNameMatch] === parsedRowData[checkedPropertyNameMatch],
    );

    if (canCheckOnlyOneItem) {
      this.props.dispatchSetCheckedItems([parsedRowData], namespace);
    } else if (!findItem) {
      this.props.dispatchSetCheckedItems([...checkedItems, parsedRowData], namespace);
    } else {
      const updateCheckedItems = checkedItems.filter(
        item => item[checkedPropertyNameMatch] !== parsedRowData[checkedPropertyNameMatch],
      );
      this.props.dispatchSetCheckedItems(updateCheckedItems, namespace);
    }
  };

  handleRowClick = (rowDataObject, params) => {
    const {
      onRowClick,
      checkable,
      listRequestOption,
      loadingList,
      disableItemCheckable,
    } = this.props;

    const { namespace = NAMESPACE } = listRequestOption;

    if (checkable && !loadingList && !disableItemCheckable(rowDataObject)) {
      this.props.dispatchSetCheckedItems([rowDataObject], namespace);
    }

    if (onRowClick && !loadingList) {
      this.props.onRowClick(params);
    }
  };

  debounceFetchData = debounce((startIndex, stopIndex) => {
    this.fetchData(startIndex, stopIndex);
  }, 300);

  onRowRightClick = (item, { event }) => {
    const {
      onContextMenuTrigger,
      disableItemCheckable,
      checkedItems,
      dispatchClearCheckedItems,
      dispatchSetMenuReference,
      dispatchSetCurrentMousePosition,
      dispatchSetMenuDisplay,
      dispatchSetCheckedItems,
      listRequestOption,
    } = this.props;
    const { namespace = NAMESPACE } = listRequestOption;
    if (!onContextMenuTrigger) {
      return;
    }

    if (!item || disableItemCheckable(item)) {
      return;
    }

    const isMulti = !(
      checkedItems.length <= 1 || !checkedItems?.find(checkedItem => isEqual(checkedItem, item))
    );

    const newCheckedItems = isMulti ? checkedItems : [item];

    onContextMenuTrigger({
      item,
      checkedItems: newCheckedItems,
      isMulti,
    });

    if (!checkedItems?.find(checkedItem => isEqual(checkedItem, item))) {
      dispatchClearCheckedItems(namespace);
      dispatchSetCheckedItems(newCheckedItems, namespace);
    }

    const uuidContextReference = uuid.v4();
    window.contextMenuStore[uuidContextReference] = event.target;
    dispatchSetMenuReference(uuidContextReference);

    const xPage = event.pageX;
    const yPage = event.pageY;
    const { x, y } = event.target.getBoundingClientRect();

    dispatchSetCurrentMousePosition({
      x: xPage - x,
      y: yPage - y,
    });

    dispatchSetMenuDisplay(true);
  };
}

InfiniteTableSelfStanding.propTypes = {
  autoFocus: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  actionsRerenderer: PropTypes.func,
  checkable: PropTypes.bool,
  disableItemCheckable: PropTypes.func,
  checkedPropertyNameMatch: PropTypes.string,
  isEmptyTable: PropTypes.bool,
  tableKey: PropTypes.string.isRequired,
  searchable: PropTypes.bool,
  intl: intlShape.isRequired,
  defaultActiveFilters: PropTypes.arrayOf(PropTypes.object),
  activeFilters: PropTypes.object,
  searchText: PropTypes.string,
  searchClassName: PropTypes.string,
  searchBarClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  actionsClassName: PropTypes.string,
  checkBoxColumnClassName: PropTypes.string,
  quickFilterClassName: PropTypes.string,
  filterActionsRendered: PropTypes.func,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  loadingList: PropTypes.bool,
  children: PropTypes.node,
  isFiltering: PropTypes.bool,
  filteringEmptyMessage: PropTypes.string,
  noDataAction: PropTypes.bool,
  noDataRenderer: PropTypes.func,
  list: PropTypes.object,
  checkedItems: PropTypes.arrayOf(PropTypes.object),
  searchInputProps: PropTypes.object,
  tableClassName: PropTypes.string,
  searchCount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sortDirection: PropTypes.string,
  sortTransformer: PropTypes.func,
  sortBy: PropTypes.string,
  sortData: PropTypes.object,
  button: PropTypes.element,
  disableSearch: PropTypes.bool,
  listRequestOption: PropTypes.shape({
    method: PropTypes.string.isRequired,
    namespace: PropTypes.string,
    url: PropTypes.string.isRequired,
    dataKey: PropTypes.string.isRequired,
    urlParams: PropTypes.object,
    excludeParamsAtSearch: PropTypes.arrayOf(PropTypes.string),
    callBack: PropTypes.func,
  }).isRequired,
  filterList: PropTypes.arrayOf(
    PropTypes.shape({
      component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
      filterPropertyName: PropTypes.string,
      filterValue: PropTypes.object,
    }),
  ),
  colorCheckBox: PropTypes.string,
  savedFilterData: PropTypes.object,
  searchComponent: PropTypes.node,
  getRowClassName: PropTypes.func,
  rendererTableHeader: PropTypes.func,
  onRowClick: PropTypes.func,
  renderAddButton: PropTypes.func,
  searchRerenderer: PropTypes.func,
  displaySearchBeforeActions: PropTypes.bool,
  onContextMenuTrigger: PropTypes.func,
  dispatchSetSearchText: PropTypes.func,
  dispatchClearSearchText: PropTypes.func,
  dispatchClearActiveFilters: PropTypes.func,
  dispatchSetActiveFilters: PropTypes.func,
  dispatchFetchMoreItems: PropTypes.func.isRequired,
  dispatchSetSortData: PropTypes.func,
  dispatchClearSortData: PropTypes.func,
  dispatchSetCheckedItems: PropTypes.func,
  dispatchClearCheckedItems: PropTypes.func,
  dispatchResetTableState: PropTypes.func,
  dispatchSetMenuDisplay: PropTypes.func.isRequired,
  dispatchSetMenuReference: PropTypes.func.isRequired,
  dispatchSetCurrentMousePosition: PropTypes.func.isRequired,
  onHandleItems: PropTypes.func,
  hasHeader: PropTypes.bool,
  canCheckAll: PropTypes.bool,
  canCheckOnlyOneItem: PropTypes.bool,
  deleteDuplicateFormTemplate: PropTypes.bool,
  threshold: PropTypes.number,
};

InfiniteTableSelfStanding.defaultProps = {
  filterList: [],
  checkedItems: [],
  checkedPropertyNameMatch: 'id',
  rowHeight: 48,
  headerHeight: 48,
  loadingList: true,
  isFiltering: false,
  noDataAction: false,
  searchable: true,
  disableSearch: undefined,
  searchComponent: null,
  sortData: {},
  sortTransformer: value => value,
  checkable: false,
  filterActionsRendered: () => null,
  rendererTableHeader: () => null,
  disableItemCheckable: () => false,
  displaySearchBeforeActions: true,
  hasHeader: false,
  canCheckAll: true,
  canCheckOnlyOneItem: false,
  filteringEmptyMessage: '',
};

const mapStateToProps = (state, { listRequestOption }) => {
  const { namespace = NAMESPACE } = listRequestOption || {};
  return createStructuredSelector({
    loadingList: selectLoadingFlag(namespace),
    searchText: selectSearchText(namespace),
    sortData: selectItemsSortData(namespace),
    params: selectRouteParams(namespace),
    activeFilters: selectActiveFilters(namespace),
    list: selectItems(namespace),
    checkedItems: selectCheckedItems(namespace),
    searchCount: selectSearchCount(namespace),
    isEmptyTable: selectIsEmptyTable(namespace),
    tableKey: selectUniqueKey(namespace),
  });
};

const mapDispatchToProps = {
  dispatchSetSearchText: setSearchText,
  dispatchLoadingListState: setLoadingFlag,
  dispatchClearSearchText: clearSearchText,
  dispatchSetActiveFilters: setActiveFilters,
  dispatchClearActiveFilters: clearActiveFilters,
  dispatchFetchMoreItems: getItems,
  dispatchSetSortData: setSortData,
  dispatchClearSortData: clearSortData,
  dispatchSetCheckedItems: setCheckedItems,
  dispatchClearCheckedItems: clearCheckedItems,
  dispatchResetTableState: resetTableState,
  dispatchSetMenuDisplay: setMenuDisplay,
  dispatchSetMenuReference: setMenuReference,
  dispatchSetCurrentMousePosition: setCurrentClickPosition,
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    forwardRef: true,
  },
);

export default withConnect(
  compose(
    injectIntl,
    withStyles(combineStyles(tableStyle)),
  )(InfiniteTableSelfStanding),
);
