import { defineMessages } from 'react-intl';

export default defineMessages({
  password_text: {
    id: 'account_settings.password_text',
    defaultMessage: 'An email to reset your password will be sent to your email address.',
  },
  companies_has_been_deleted: {
    id: 'global.companies_has_been_deleted',
    defaultMessage: 'Companies has been deleted',
  },
  company_has_been_deleted: {
    id: 'global.company_has_been_deleted',
    defaultMessage: 'Company has been deleted',
  },
  phase_successfully_deleted: {
    id: 'global.phase_successfully_deleted',
    defaultMessage: 'Phases successfully deleted',
  },
  creation_successful: {
    id: 'global.creation_successful',
    defaultMessage: 'Creation successful',
  },
  library_update_published: {
    id: 'global.library_update_published',
    defaultMessage: 'This library update has been published.',
  },
  download_in_progress: {
    id: 'global.download_in_progress',
    defaultMessage: 'Download in progress',
  },
  form_import_in_progress: {
    id: 'global.form_import_in_progress',
    defaultMessage:
      'Forms import in progress, an email will be sent when it’s done. You can continue using the app',
  },
  processing_file_reload_companies: {
    id: 'global.processing_file_reload_companies',
    defaultMessage: 'Processing file. Reload the page later to see the companies.',
  },
  error_refresh_contact_support: {
    id: 'global.error_refresh_contact_support',
    defaultMessage:
      'An error occurs. Please refresh the page. If the problem persists contact our support team',
  },
  error_login_expired: {
    id: 'global.error_login_expired',
    defaultMessage: 'Login expired, you need to login again',
  },
  forbidden_error: {
    id: 'global.forbidden_error',
    defaultMessage: 'You cannot perform this action.',
  },
  services_under_maintenance: {
    id: 'global.services_under_maintenance',
    defaultMessage: 'Services under maintenance. Try again later.',
  },
  update_successful: {
    id: 'global.update_successful',
    defaultMessage: 'Update successful',
  },
  location_successfully_removed: {
    id: 'global.location_successfully_removed',
    defaultMessage: 'Location successfully removed',
  },
  generation_form_log_file: {
    id: 'global.generation_form_log_file',
    defaultMessage: 'Forms log file in generation. You can continue using the app',
  },
  file_will_be_downloaded_when_ready: {
    id: 'global.file_will_be_downloaded_when_ready',
    defaultMessage: 'File will be downloaded when ready',
  },
  inexisting_email: {
    id: 'global.inexisting_email',
    defaultMessage: 'This email doesn’t exist, please check the spelling',
  },
  export_in_progress: {
    id: 'global.export_in_progress',
    defaultMessage: 'Export in progress',
  },
  reactivated_organization: {
    id: 'reactivated_organization',
    defaultMessage: 'The organization has been reactivated',
  },
  workspace_created: {
    id: 'global.workspace_created',
    defaultMessage: 'Workspace “{name}” created.',
  },
  export_fail: {
    id: 'global.export_fail',
    defaultMessage: 'Generation of export failed',
  },
  verification_email_sent: {
    id: 'global.verification_email_sent',
    defaultMessage: 'Email sent to {email}',
  },
  something_went_wrong: {
    id: 'global.something_went_wrong',
    defaultMessage: 'Something went wrong, please try again or contact our support',
  },
  masse_import_observation_started_description: {
    id: 'global.masse_import_observation_started_description',
    defaultMessage:
      'Items import started, refresh page to see results. An email will be sent when completed.',
  },
  observation_status_not_changed: {
    id: 'global.observation_status_not_changed',
    defaultMessage:
      'Observation {observation} could not be passed from status {status} to status {newStatus}',
  },
  has_been_published_successfully: {
    id: 'global.has_been_published_successfully',
    defaultMessage: '{name} has been published successfully',
  },
  smart_zoning_in_progress: {
    id: 'global.smart_zoning_in_progress',
    defaultMessage:
      'Smart zoning in progress, you can come back later: a message will appear when ready to review.',
  },
  reports_generation_launched: {
    id: 'global.reports.success_toast.generation_launched',
    defaultMessage:
      'Report creation in progress, an email will be sent when it’s ready. You can continue using the app.',
  },
  reports_scheduling_success_toast: {
    id: 'reports.scheduling.success_toast',
    defaultMessage: 'Scheduled sending planned',
  },
  import_in_progress: {
    id: 'global.import_in_progress',
    defaultMessage: 'Import in progress',
  },
  masse_import_form_started_description: {
    id: 'global.masse_import_form_started_description',
    defaultMessage:
      'Forms import started, refresh page to see results. An email will be sent when completed.',
  },
  members_import_in_progress: {
    id: 'global.members_import_in_progress',
    defaultMessage:
      'Members import in progress, an email will be sent when it’s done. You can continue using the app',
  },
  imported_template_is_empty: {
    id: 'global.imported_template_is_empty',
    defaultMessage: 'Imported template is empty',
  },
  workspace_members_invite_toast_error: {
    id: 'workspace.members.invite_toast.error',
    defaultMessage:
      '"We were not able to import any members because the template is not properly filled',
  },
  item_moved_to_module: {
    id: 'item_moved_to_module',
    defaultMessage: 'The item has successfully been moved to {module}.',
  },
  creation_successful_with_refresh: {
    id: 'global.creation_successful_with_refresh',
    defaultMessage:
      'Creation successful. However you should refresh the page to see the element you have just created.',
  },
  meeting_generation_alert: {
    id: 'meeting.details.generate.status.generating',
    defaultMessage: 'PDF is being generated...',
  },
  link_copied: {
    id: 'global.link_copied',
    defaultMessage: 'Link copied',
  },
  referential_file_importation_error: {
    id: 'referential.file_importation_error',
    defaultMessage: 'An error occurred during referential file importation.',
  },
  referentials_update_message: {
    id: 'referentials.update_description',
    defaultMessages: '"We are uploading your file. Refresh the page once it is done.',
  },
  item_share_in_a_group: {
    id: 'items.share_groups.web.success_toast.one.one',
    defaultMessages: '{0} item was shared in the discussion',
  },
  items_share_in_a_group: {
    id: 'items.share_groups.web.success_toast.one.other',
    defaultMessages: '{0} items were shared in the discussion',
  },
  item_share_in_groups: {
    id: 'items.share_groups.web.success_toast.other.one',
    defaultMessages: '{0} item was shared in {1} discussions',
  },
  items_share_in_groups: {
    id: 'items.share_groups.web.success_toast.other.other',
    defaultMessages: '{0} items were shared in {1} discussions',
  },
  file_is_being_downloaded: {
    id: 'form_builder.custom_reports.download_dataset.message',
    defaultMessage: 'Your file is being downloaded...',
  },
  file_is_being_uploaded: {
    id: 'form_builder.custom_reports.upload_template.message',
    defaultMessage: 'Your file is being uploaded...',
  },
  share_to_groups: {
    id: 'items.share_to_groups',
    defaultMessage:
      'Item has been shared in {count} {count, plural, =0 {# discussion} one {# discussion} other {# discussions}}',
  },
  grid_answers_added: {
    id: 'forms.form_builder.grid_answer.import_answers.success_message',
    defaultMessage: 'A grid answer was added with {number} sub-questions',
  },
  grid_answer_added: {
    id: 'forms.form_builder.grid_answer.import_answer.success_message',
    defaultMessage: 'A grid answer was added with a sub-question',
  },
  item_linked: {
    id: 'global.item_linked',
    defaultMessage: '{count, plural, one {# item was} other {# items were} linked}',
  },
});
