import { fromJS } from 'immutable';

export const updateModuleItem = ({ state, module }) => {
  const moduleList = state.get('modules');
  const index = moduleList.findIndex(item => item.get('id') === module.id);
  return state.set(
    'modules',
    fromJS([...moduleList.slice(0, index), module, ...moduleList.slice(index + 1)]),
  );
};
