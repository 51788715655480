import { setPopoupBlockerObject } from 'containers/GlobalWrapper/actions';

export const downloadFile = (url, fileName) => {
  window.finalcadReduxStore.dispatch(setPopoupBlockerObject({ url, name: fileName }));
};

const hasFileExtension = (filename, mimeType) => {
  const fileNameArray = filename.split('.');

  if (fileNameArray.length > 1 && filename.split('.').pop() && !mimeType) {
    return true;
  }
  if (fileNameArray.length > 1 && filename.split('.').pop()) {
    const extension = fileNameArray[fileNameArray.length - 1];
    switch (extension) {
      case 'doc':
        return mimeType === 'application/msword';
      case 'docx':
        return (
          mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      case 'jpg':
        return mimeType === 'image/jpeg';
      case 'jpeg':
        return mimeType === 'image/jpeg';
      case 'png':
        return mimeType === 'image/png';
      case 'pdf':
        return mimeType === 'application/pdf';
      case 'xls':
        return mimeType === 'application/vnd.ms-excel';
      case 'xlsx':
        return mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'dwg':
        return mimeType === 'application/acad';
      case 'rar':
        return mimeType === 'application/vnd.rar';
      case 'zip':
        return mimeType === 'application/zip';
      case 'md':
        return mimeType === 'md.dwg';
      case 'csv':
        return mimeType === 'text/csv';
      default:
        return false;
    }
  }
  return false;
};

export const getExtension = item => {
  const type =
    item.media_resource?.mime_type ||
    item.media_resource_mime_type ||
    item?.export_media_resource?.mime_type ||
    item?.mime_type;

  switch (type) {
    case 'application/msword':
      return '.doc';
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return '.docx';
    case 'image/jpeg':
      return '.jpg';
    case 'image/png':
      return '.png';
    case 'application/pdf':
      return '.pdf';
    case 'application/vnd.ms-excel':
      return '.xls';
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return '.xlsx';
    case 'application/acad':
      return '.dwg';
    case 'text/csv':
      return '.csv';
    case 'application/vnd.rar':
    case 'application/x-rar-compressed':
      return 'rar';
    case 'application/zip':
    case 'application/x-zip-compressed':
    case 'multipart/x-zip':
      return 'zip';
    case 'md.dwg':
      return 'md';
    default:
      return `.${type}`;
  }
};

const getMIMEType = item =>
  item?.media_resource?.mime_type ||
  item?.media_resource_mime_type ||
  item?.export_media_resource?.mime_type;

export const getFileName = item => {
  const filename =
    item?.name || item?.media_resource?.file_name || item?.export_media_resource.file_name;

  if (filename && hasFileExtension(filename, getMIMEType(item))) {
    return filename;
  }
  if (filename && !!getMIMEType(item)) {
    return `${filename}${getExtension(item)}`;
  }
  return item.name;
};

export const getFileUrl = item =>
  item.media_resource?.url ||
  item.media_resource_url ||
  item.thumbnail_url ||
  item?.export_media_resource?.url;

export const getPlanFileFormat = item => {
  const type = item.media_resource?.mime_type || item.media_resource_mime_type;
  switch (type) {
    case 'image/jpeg':
    case 'image/png':
      return 'image';
    case 'application/pdf':
      return 'pdf';
    case 'application/acad':
      return 'dwg';
    default:
      return 'ifc';
  }
};

export const downloadBlob = (result, fileName) => {
  const fakeUrl = window.URL.createObjectURL(new Blob(result));
  downloadFile(fakeUrl, fileName);
};

export const getBinaryDataFileWindow = uuidBlobFile =>
  window.blobStore[uuidBlobFile].replace(/^data:.+;base64,/, '');

const chunkSize = 8092; // 8KB

export function readFileFirstLine(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let offset = 0;
    let line = '';

    const processChunk = (chunkOffset, size) => {
      const blob = file.slice(chunkOffset, chunkOffset + size);
      reader.readAsText(blob);
    };

    reader.onload = e => {
      const text = e.target.result;
      const newLineIndex = text.indexOf('\n');
      if (newLineIndex !== -1) {
        line += text.slice(0, newLineIndex);
        resolve(line);
        return;
      }

      line += text;
      offset += chunkSize;

      if (offset >= file.size) {
        resolve(line);
      } else {
        // eslint-disable-next-line no-use-before-define
        processChunk(offset, chunkSize);
      }
    };

    reader.onerror = e => {
      reject(e.target.error);
    };

    processChunk(offset, chunkSize);
  });
}
