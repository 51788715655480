const scope = 'membersPage/';
export const GET_PROJECT_ROLES = `${scope}GET_PROJECT_ROLES`;
export const SET_PROJECT_ROLES = `${scope}SET_PROJECT_ROLES`;
export const GET_PROJECT_NON_MEMBERS = `${scope}GET_PROJECT_NON_MEMBERS`;
export const SET_PROJECT_NON_MEMBERS = `${scope}SET_PROJECT_NON_MEMBERS`;
export const ADD_PROJECT_MEMBERS = `${scope}ADD_PROJECT_MEMBERS`;
export const SET_MEMBER_ROLE = `${scope}SET_MEMBER_ROLE`;
export const SET_BULK_MEMBER_ROLE = `${scope}SET_BULK_MEMBER_ROLE`;
export const REMOVE_BULK_MEMBERS = `${scope}REMOVE_BULK_MEMBERS`;
export const GET_SAME_AND_BELOW_PROJECT_ROLES = `${scope}GET_SAME_AND_BELOW_PROJECT_ROLES`;
export const SET_SAME_AND_BELOW_PROJECT_ROLES = `${scope}SET_SAME_AND_BELOW_PROJECT_ROLES`;
