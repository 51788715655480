import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import moment from 'moment';
import uuid from 'uuid';

import config from 'config/config';
import checkOrSetSlash from 'utils/checkOrSetSlash';
import localStorageUser from 'utils/localStorageUser';
import { getProjectCompaniesFilter } from 'utils/constants';

const rootProjectPath = `${checkOrSetSlash(config.apiHostGateway, 'apiHostGateway')}api/`;

export function prepareFCHeader(prepare = headers => headers) {
  return (headers, { getState }) => {
    const oktaToken = localStorageUser.getOktaTokenStorage();
    const token = oktaToken?.accessToken?.accessToken;
    headers.set('content-type', 'application/json');
    headers.set('Cache-Control', 'No-Store');
    headers.set('Allow-Access-Control-Origin', '*');

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return prepare(headers, { getState });
  };
}

export const companiesApi = createApi({
  reducerPath: 'companiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: rootProjectPath,
    prepareHeaders: prepareFCHeader(),
  }),
  tagTypes: ['Companies'],
  endpoints: builder => ({
    getCompanies: builder.query({
      query: ({ projectId, search_term, pagination }) => ({
        method: 'POST',
        url: `${getProjectCompaniesFilter()}/projects/${projectId}/companies/filter/?limit=${
          pagination.pageSize
        }&offset=${pagination?.page * pagination?.pageSize}`,
        body: {
          search_term,
        },
      }),
      providesTags: ['Companies'],
    }),
    addCompanies: builder.mutation({
      query: ({ projectId, names }) => ({
        method: 'POST',
        url: `projects/${projectId}/companies/create-bulk`,
        body: {
          companies: names.map(name => ({
            id: uuid.v4(),
            name,
            client_created_at: moment(),
          })),
        },
      }),
      invalidatesTags: ['Companies'],
    }),
    deleteCompanies: builder.mutation({
      query: ({ projectId, ids }) => ({
        method: 'POST',
        url: `projects/${projectId}/companies/delete-bulk`,
        body: {
          company_ids: ids,
        },
      }),
      invalidatesTags: ['Companies'],
    }),
    editCompany: builder.mutation({
      query: ({ projectId, companyId, name, reference }) => ({
        method: 'PUT',
        url: `projects/${projectId}/companies/${companyId}/`,
        body: {
          name,
          reference,
          client_created_at: moment(),
        },
      }),
      invalidatesTags: ['Companies'],
    }),
    addMembers: builder.mutation({
      query: ({ projectId, members, companyId }) => ({
        method: 'POST',
        url: `projects/${projectId}/companies/${companyId}/add-members`,
        body: {
          user_ids: members,
        },
      }),
      invalidatesTags: ['Companies'],
    }),
    removeMembers: builder.mutation({
      query: ({ projectId, members, companyId }) => ({
        method: 'POST',
        url: `projects/${projectId}/companies/${companyId}/remove-members`,
        body: {
          user_ids: members,
        },
      }),
      invalidatesTags: ['Companies'],
    }),
    importCompanies: builder.mutation({
      query: ({ projectId, data64 }) => ({
        method: 'POST',
        url: `projects/${projectId}/companies/excel-bulk`,
        body: {
          data: data64,
        },
      }),
      invalidatesTags: ['Companies'],
    }),
    invalidatesTags: ['Companies'],
  }),
});

export const {
  useGetCompaniesQuery,
  useAddCompaniesMutation,
  useDeleteCompaniesMutation,
  useAddMembersMutation,
  useRemoveMembersMutation,
  useEditCompanyMutation,
  useImportCompaniesMutation,
} = companiesApi;
